import React from 'react'
import styled from 'styled-components'
import theme from '../theme/theme'

interface AboutContentWrapperProps {
  backgroundColor?: string
}

const AboutContentWrapper = styled.div<AboutContentWrapperProps>`
  background-color: ${({ backgroundColor }) => backgroundColor ?? 'white'};
  padding: 22px 22px 10px 22px;
  white-space: pre-line;
  border-radius: 21px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 35px 22px;
  }
`
interface TitleProps {
  color?: string
}

const Title = styled.h2<TitleProps>`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${({ color }) => color ?? theme.colors.body};

  @media (min-width: ${theme.breakpoints.md}) {
    font-size: 24px;
    line-height: 29px;
  }
`

interface ParagraphProps {
  color?: string
}

const Paragraph = styled.p<ParagraphProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${({ color }) => color ?? theme.colors.body};
  margin-bottom: 11px;

  @media (min-width: ${theme.breakpoints.md}) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
  }
`

interface AboutContentContainerProps {
  noLimitWidth?: boolean
}

const AboutContentContainer = styled.div<AboutContentContainerProps>`
  max-width: ${({ noLimitWidth }) => (noLimitWidth ? 'unset' : '590px')};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

interface AboutContentProps {
  colored?: boolean
  noLimitWidth?: boolean
  children: React.ReactNode
}

const AboutContent: React.FC<AboutContentProps> = ({
  children,
  colored = false,
  noLimitWidth = false,
}) => (
  <AboutContentWrapper
    backgroundColor={colored ? theme.colors.secondary : 'white'}
  >
    <AboutContentContainer noLimitWidth={noLimitWidth}>
      {children}
    </AboutContentContainer>
  </AboutContentWrapper>
)

export default Object.assign(AboutContent, {
  Title,
  Paragraph,
})
